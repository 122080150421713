import useSiteMetadata from '@helpers/useSiteMetadata'
import React from 'react'
import Helmet from 'react-helmet'

import SchemaOrg from './schema-org'

const Seo = ({
  title,
  description,
  excerpt,
  meta,
  keywords,
  author,
  date,
  timeToRead,
  children,
  thumbnail,
  siteUrl: siteUrlProp,
  pageUrl,
  slug,
  isBlogPost,
  otherTags = [],
}) => {
  const site = useSiteMetadata()

  const siteUrl = siteUrlProp || site.canonicalUrl

  description = excerpt || description || site.description

  let thumbnailUrl
  if (isBlogPost) {
    thumbnailUrl = thumbnail && thumbnail.hero && thumbnail.hero.src
  } else {
    thumbnail = thumbnail && thumbnail.hero && thumbnail.hero.src
    thumbnailUrl =
      (thumbnail && siteUrl && `${siteUrl}${thumbnail}`) ||
      `${siteUrl}/gtr-social-share.png`
  }

  const url = isBlogPost
    ? `${site.canonicalUrl}${slug}`
    : pageUrl
    ? `${site.canonicalUrl}${pageUrl}`
    : site.canonicalUrl
  const metaTags = [
    { itemprop: 'name', content: title || site.title },
    { name: 'description', content: description },

    { property: 'og:title', content: title || site.title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: thumbnailUrl },
    { property: 'og:url', content: url },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: 'guesstherose' },
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (date) {
    metaTags.push({ name: 'article:published_time', content: date })
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
    metaTags.push({ name: 'twitter:data1', value: `${timeToRead} min read` })
  }
  if (isBlogPost) metaTags.push({ content: 'article', property: 'og:type' })

  if (meta) {
    metaTags.push(meta)
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        meta={[...metaTags, ...otherTags]}
        title={title || site.title}
        titleTemplate={title ? `%s | ${site.title}` : null}
      >
        {children}
      </Helmet>
      <SchemaOrg
        author={author}
        canonicalUrl={site.canonicalUrl}
        datePublished={date}
        defaultTitle={site.title}
        description={description}
        image={thumbnailUrl}
        isBlogPost={isBlogPost}
        organization={site.organization}
        title={title}
        url={url}
      />
    </>
  )
}

export default Seo
